import { IInternalTelemetry, LogLevel } from '@msdyn365-commerce/telemetry-internal';

export const logDebug = (debugMessage: string, telemetry: IInternalTelemetry | undefined): void => {
    telemetry && telemetry.log(LogLevel.Debug, debugMessage);
};

export const logError = (errorMessage: string, telemetry: IInternalTelemetry | undefined): void => {
    telemetry && telemetry.log(LogLevel.Error, errorMessage);
};

/**
 * Returns true if the given string matches an URL pattern
 *
 * @param source The string to check against
 */
export const isAbsoluteUrl = (source: string): boolean => {
    return /^(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/.test(source);
};

export const isRelativeUrl = (source: string): boolean => {
    return /^(?!www\.|(?:http|ftp)s?:\/\/|[A-Za-z]:\\|\/\/).*(\.js){1}$/.test(source);
};
